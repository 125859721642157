import {
	REGISTER_VALIDATOR, BECOME_DISTRIBUTOR_VALIDATOR, PREREGISTER_VALIDATOR, CHECKOUT_VALIDATOR,
	RENEWAL_VALIDATOR, USER_INFO, PICKUP_PURCHASE_INFORMATION, PICKUP_UPDATE_PURCHASE_INFORMATION, PICKUP_PURCHASE,
} from '@/config/endpoint';
import Req from '../AxiosRequest';

class Purchase {
	constructor() {
		this.data = new Req();
		this.customDataObject = {};
		this.errors = this.data.errors;
		this.loading = this.data.loading;
		this.pagination = this.data.pagination;
	}

	clear() {
		this.data.clear();
	}

	getSponsorInfo(user) {
		const { method, endpoint } = USER_INFO;
		return this.data[method](endpoint(user)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getPurchaseInformation() {
		const { method, endpoint } = PICKUP_PURCHASE_INFORMATION;
		return this.data[method](endpoint).then((response) => response).catch((error) => Promise.reject(error));
	}

	updatePurchaseInformation(payload) {
		const { method, endpoint } = PICKUP_UPDATE_PURCHASE_INFORMATION;
		return this.data[method](endpoint, payload).then((response) => response).catch((error) => Promise.reject(error));
	}

	confirmPurchase(payload) {
		const { method, endpoint } = PICKUP_PURCHASE;
		return this.data[method](endpoint, payload).then((response) => response).catch((error) => Promise.reject(error));
	}

	validate(payload, currentModule, userId) {
		if (typeof payload === 'undefined' || typeof payload.step === 'undefined') {
			return Promise.reject(new Error('invalid_step'));
		}

		if (currentModule === 'RegisterOld' || currentModule === 'Register') {
			const { method, endpoint } = REGISTER_VALIDATOR;
			return this.data[method](endpoint, payload).then((response) => response).catch((error) => Promise.reject(error));
		}

		if (currentModule === 'Checkout') {
			const { method, endpoint } = CHECKOUT_VALIDATOR;
			return this.data[method](endpoint, payload).then((response) => response).catch((error) => Promise.reject(error));
		}

		if (currentModule === 'BecomeDistributor') {
			const { method, endpoint } = BECOME_DISTRIBUTOR_VALIDATOR;
			return this.data[method](endpoint, payload).then((response) => response).catch((error) => Promise.reject(error));
		}

		if (currentModule === 'Renewal' && typeof userId !== 'undefined') {
			const { method, endpoint } = RENEWAL_VALIDATOR;
			return this.data[method](endpoint(userId), payload).then((response) => response).catch((error) => Promise.reject(error));
		}

		if (currentModule === 'Preregister' || currentModule === 'SoftRegister') {
			const { method, endpoint } = PREREGISTER_VALIDATOR;
			return this.data[method](endpoint, payload).then((response) => response).catch((error) => Promise.reject(error));
		}

		return Promise.reject(new Error('module_not_found'));
	}
}

export default Purchase;
